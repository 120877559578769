import React from "react"
import { css, jsx } from "@emotion/core"
import img from "../../images/employees/tammi_hall.png"

const Employees = () => (
  <div>
    <ul css={{ listStyle: "none" }}>
      <li
          key="001"
          css={{
            display: `flex`,
            borderTop: `1px solid #abadb3`,
            padding: `40px 15px 35px 15px`,
            alignItems: `center`,
            "@media (max-width: 763px)": {
              flexWrap: `wrap`,
              justifyContent: `center`,
            },
          }}
        >
          <div>
            <img
              css={{
                borderRadius: `50%`,
                width: `150px`,
              }}
              src="/headshots/tammi-hall.png"
              alt="Tammi Rogers Hall"
            />
          </div>
          <div css={{ marginLeft: `30px` }}>
            <h3>
              Tammi Rogers Hall,{" "}
              <span css={{ fontSize: `1.2rem` }}>Executive Director</span>
            </h3>
          </div>
        </li>
    </ul>
  </div>
)



export default Employees


import React from "react"
import AboutLayout from "../../components/layouts/aboutLayout"
import BoardMembers from "../../components/boardMembers/boardMembers"
import Employees from "../../components/employees/employees"

const TeamPage = props => (
  <AboutLayout pageTitle="Meet Our Team">
    <BoardMembers />
    <Employees />
  </AboutLayout>
)

export default TeamPage

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { css, jsx } from "@emotion/core"
import img from "../../images/employees/tammi_hall.png"
import PropTypes from "prop-types"

//import PlaceholderImg from "../../images/board-members/person-placeholder.jpg"

const BoardMembers = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allBoardMembersJson {
          edges {
            node {
              id
              name
              position
              company
              image
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <ul
          css={{
            listStyle: `none`,
            display: `flex`,
            flexWrap: `wrap`,
            overflow: `hidden`,
            margin: `20px 0`,
          }}
        >
          {getBoardMembers(data)}
        </ul>
      </div>
    )}
  />
)

function getBoardMembers(data) {
  const boardMembersArray = []
  data.allBoardMembersJson.edges.forEach(item =>
    boardMembersArray.push(
      <li
        css={{
          flex: `1 1 20em`,
          marginLeft: `-1px`,
          padding: `35px 25px`,
          display: `flex`,
          minWidth: `33%`,
          flexDirection: `column`,
          justifyContent: `center`,
          textAlign: `center`,
          marginBottom: `10px`,
        }}
        key={item.node.id}
      >
        <div>
          <img
            css={{
              borderRadius: `50%`,
              width: `120px`,
              marginBottom: `20px`,
            }}
            src={item.node.image !== "" ? `/headshots/${item.node.image}.png` : `/headshots/placeholder.png`}
            alt={item.node.name}
          />
        </div>
        <div css={{}}>
          <p>{item.node.position}</p>
          <h2>{item.node.name}</h2>
          <p css={{ fontSize: `1.2rem` }}>{item.node.company}</p>
        </div>
      </li>
    )
  )
  return boardMembersArray
}

export default BoardMembers
